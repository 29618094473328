export default {
  header: {
    home: "Home",
    about_us: "About us",
    services: "Services",
    portfolio: "Portfolio",
    pack_my_project: "Pack my project!",
    inquire: "Inquire",
  },
  footer: {
    contact: "Contact",
    glossary: "Glossary",
    more_about_services: "More About Services",
    terms_conditions: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    llc: "© 2024 Polyus Studio LCC",
  },
  buttons: {
    get_to_know_the_team: "Get to know the team",
    view_the_project: "View the project",
    view_all: "View all",
    apply_now: "Apply now",
    get_a_consultation: "Get a consultation",
    go_home: "Go home",
    view_the_website: "View the website",
    view_the_case: "View the case",
  },
  forms: {
    name: "Name*",
    email: "Email*",
    submit: "Submit",
    select: {
      select: "Select a service*",
      web_design: "Web design",
      web_development: "Web development",
      content_strategy: "Content strategy",
      business_process: "Business process automation",
      brand_strategy: "Brand strategy & business identity",
      all: "Select all",
    },
  },
  cookie: {
    title: "Notice.",
    description:
      "TermsFeed uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our",
  },
  home: {
    styling_your_business: "Styling your business",
    fill_in_for:
      "Let’s talk for the form if you are interested in the services from the list",
    abouts: {
      1: {
        title: "We",
        description: "are a team of creative digital gurus",
      },
      2: {
        title: "We",
        description: "impress with our professionalism proven by our portfolio",
      },
      3: {
        title: "We",
        description: "follow the latest trends",
      },
      4: {
        title: "We",
        description:
          "do not create just websites, but technology resources that sell",
      },
    },
    services: {
      1: {
        iconAlt: "Icon Web development",
        title: "Web development",
        description: "Developing your authentical website",
      },
      2: {
        iconAlt: "Icon Web design",
        title: "Web design",
        description: "Developing the style and ergonomics of your website",
      },
      3: {
        iconAlt: "Icon Brand strategy & business identity",
        title: "Brand strategy & business identity",
        description: "Developing a unique style for your business",
      },
      4: {
        iconAlt: "Icon Content strategy",
        title: "Content strategy",
        description:
          "Producing, creating and structuring content for your business",
      },
      5: {
        iconAlt: "Icon Business process automation",
        title: "Business process automation",
        description:
          "Digitalization of business via CRM to increase efficiency",
      },
    },
    portfolio_projects: {
      1: {
        title: "Vera Fund",
        description:
          "A project with a target audience that is special in every sense. In our hands, it was visually updated and gained more 'air'. We removed massive bright details from the brandbook and turned them into neat accents. Discover new materials and resources for the project.",
        iconAlt: "Vera Fund Project",
      },
      2: {
        title: "Coral Ridge Townhomes",
        description:
          "One of our first projects from logo to sales is a complex of 8 luxury townhomes in a unique neighborhood in Fort Lauderdale, Florida. In this project an important task was to highlight the features of Coral Ridge.  We transferred relevant updates into the project, from the branding to the advertising materials.",
        iconAlt: "Coral Ridge Townhomes Project",
      },
      3: {
        title: "Aventura Quattro",
        description:
          "A project with a target audience that is special in every sense. In our hands, it was visually updated and gained more 'air'. We removed massive bright details from the brandbook and turned them into neat accents. Discover new materials and resources for the project.",
        iconAlt: "Aventura Quattro Project",
      },
    },
  },
  about_us: {
    h1: "Meet our team",
    boss: {
      name: "Nick Polyushkin",
      job: "Chief Executive Officer",
      text: "Our inspirational leader with an impressive background in marketing, digital marketing and one of the most challenging businesses - real estate. Nick has also excelled at one of the most successful marketing agencies Saatchi&Saatchi. A high level of responsibility and the ability to see more, define Nick and our team's approach to business and clients",
    },
    evgenia: {
      name: "Evgenia Zheltukhina",
      job: "Marketing Director",
    },
    sergey: {
      name: "Sergey Spirin",
      job: "IT Director",
    },
    michael: {
      name: "Michael Polupanov",
      job: "HR Director",
    },
    olya: {
      name: "Olya Ivanova",
      job: "UX/UI designer",
    },
    alexandra: {
      name: "Sasha Podugolnikova",
      job: "SMM specialist",
    },
    vitaly: {
      name: "Vitaly Bobrov",
      job: "Youtube Developer",
    },
  },
  services: {
    block_1: {
      top_text_1: "Do clients even know about your project? ",

      top_text_2:
        "We will pack it so that clients will not just know about you, but love you, bringing in big sales.",
    },
    block_2: [
      {
        title: "Web development",
        li: [
          "Identify technologies and tools",
          "Development plan with steps and deadlines",
          "Layout and frontend development",
          "Backend development",
          "Testing and customization of site systems",
          "Placing the site on the server",
        ],
        link: "ApplyNow",
        iconSrc: "/img/services/web_development.webp",
        orderBlock1:
          "lg:order-2 justify-end order-2 rounded-tr-none rounded-br-4xl rounded-bl-4xl lg:rounded-tr-4xl lg:rounded-br-4xl lg:rounded-bl-none",
        orderBlock2:
          "lg:order-1 order-1 rounded-tl-4xl rounded-tr-4xl lg:rounded-bl-4xl lg:rounded-tr-none",
        orderBlockAll: "items-end justify-end mb-6 md:mb-8 lg:mb-0",
      },
      {
        title: "Web design",
        li: [
          "Website design development",
          "Landing design development",
          "Email/email chains design",
          "Visuals for social media",
          "Web business identity",
        ],
        link: "ApplyNow",
        iconSrc: "/img/services/web_design.webp",
        orderBlock1:
          "lg:order-1 order-2 rounded-tr-none justify-end rounded-br-4xl lg:rounded-br-none rounded-bl-4xl lg:rounded-bl-4xl lg:rounded-tr-none lg:rounded-tl-4xl",
        orderBlock2:
          "lg:order-2 order-1 rounded-tl-4xl rounded-tr-4xl lg:rounded-tl-none lg:rounded-tr-4xl lg:rounded-br-4xl lg:rounded-bl-none",
        orderBlockAll: "items-end justify-end",
      },
    ],
    block_3: {
      h2: "Brand strategy & Business Identity",
      li_1: "Brandbook (logo, fonts, brand colors, guide, etc)",
      li_2: "Business Identity ( letter folders, business cards, letterheads, brochures, merchandise, etc)",
      p: "All of materials would be ready for printing",
    },
    block_4: {
      h2: "Content strategy",
      p: "Developing of content plans, content guidelines, scripts and terms of reference",
      h3_1: "Social Media Development",
      li_1_1: "Audit of current social networks",
      li_1_2: "Preparation of a customized content strategy",
      li_1_3: "Implementation of content strategy",
      li_1_4:
        "Realization of promotion through various channels of attracting traffic in social networks",
      li_1_5: "Digitization and analysis of statistics",

      h3_2: "Youtube channel strategy",
      li_2_1: "Analyzing your YouTube channel",
      li_2_2: "YouTube content management",
      li_2_3: "YouTube channel promotion",
      li_2_4: "YouTube monetization",
      li_2_5: "Optimizing your YouTube strategy",
    },
    block_5: {
      h2: "Business process automation",
      li: [
        "Installation and startup of CRM Bitrix24",
        "Implementation and Customization of CRM Bitrix24",
        "Integration of business processes with CRM Bitrix24",
        "Integration of services with CRM Bitrix24",
      ],
    },
  },
  portfolio: {
    h1: "Portfolio",
    website: "website",
    project: "case",
    yellow_block: "It could be your project",
    vera_projects: {
      h2: "Complete project packaging",
      project_1: {
        title: "Vera Fund Project",
      },
      project_2: {
        title: "Aventura Quattro Project",
      },
      project_3: {
        title: "Vera Realty Project",
      },
      project_4: {
        title: "Coral Ridge Townhomes Project",
      },
      project_5: {
        title: "Vera Office Tower Project",
      },
      project_6: {
        title: "Co Living Project",
      },
      project_7: {
        title: "AAM Project",
      },
      project_8: {
        title: "Vera Menegement Project",
      },
      project_9: {
        title: "Vera Finder Project",
      },
      project_10: {
        title: "Aventura Apartments Project",
      },
      project_11: {
        title: "CE Pony Project",
      },
    },
    projects: {
      h2: "Websites based on a ready-made identity",
      project_1: {
        title: "The Elser Project",
      },
      project_2: {
        title: "Baccarat Project",
      },
      project_3: {
        title: "Polyus Consulting Project",
      },
      project_4: {
        title: "One Park Tower Project",
      },
      project_5: {
        title: "St Regis Project",
      },
      project_6: {
        title: "Valusso Referral Project",
      },
      project_7: {
        title: "Waldorf Astoria Project",
      },
      project_8: {
        title: "Casa Bella Project",
      },
    },
    // ------
    // ------
    view: "View ",
    back: "< back",
    next_case: "next case >",
    menu_items: {
      about_the_project: {
        title: "About the project",
        vera_fund: {
          headline: "Vera Fund",
          content:
            'A project with a target audience that is special in every sense. In our hands, it was visually updated and gained more "air". We removed massive bright details from the brandbook and turned them into neat accents. Discover new materials and resources for the project.',
        },
        coral_ridge: {
          headline: "Coral Ridge Townhomes",
          content:
            "One of our first projects from logo to sales is a complex of 8 luxury townhomes in a unique neighborhood in Fort Lauderdale, Florida. In this project an important task was to highlight the features of Coral Ridge, its ecological purity, proximity to the ocean, the world famous reef and at the same time perfectly developed infrastructure. Living there is truly a pleasure. We took these impressions and transferred them into the project, from the branding to the advertising materials.",
        },
        aventura_quattro: {
          headline: "Aventura Quattro",
          content:
            "Aventura Quattro - Our complex project, the goal of which was to create effective resources and tools that fully present and highlight the property. When we started the project, it had only a name. Look at what it is now.",
          image: "/img/portfolio_aventura_quattro/path_hover.svg",
          hover_img: "hover:scale-x-125 transition ease-out delay-0",
        },
      },
      business_identity: {
        title: "Business Identity",
        vera_fund: {
          content:
            "A unique identity was developed, based on a modern, easy-to-read font; a palette that implies accentuation through color; and graphic elements that emphasize the lightness and simplicity of the logo.",
        },
        coral_ridge: {
          content:
            "A project imbued with the features of the unique neighborhood of Coral Ridge, Fort Lauderdale, Florida. The proximity to the ocean and coral reef is reflected in every element of the project's brand strategy. We developed a clear identity directly associated with this location. ",
        },
        aventura_quattro: {
          content:
            "Identity for a rental apartment complex. The building itself is being built in a modern boutique style, which had to be reflected in the visual elements, from the logo to the color schemes of the project.",
        },
      },
      web_design_development: {
        title: "Web design/development",
        vera_fund: {
          content:
            "A responsive design was created for a multi-page website with accompanying materials (such as email newsletters, web materials to run ads, etc).",
        },
        coral_ridge: {
          content:
            "A responsive design was created for a multi-page website with accompanying materials (such as email newsletters, web materials to run ads, etc).",
        },
        aventura_quattro: {
          content:
            "A responsive design was created for a multi-page website with accompanying materials (such as email newsletters, web materials to run ads, etc).",
        },
      },
      crm_integration: {
        title: "CRM Integration",
        vera_fund: {
          content:
            "Full featured project management includes collaboration across functions, social CRM applications, big data analytics & insights, access permissions, advanced reporting & Gantt charts, employee workload management, automated workflows & templates, and a mobile app.",
        },
        coral_ridge: {
          content:
            "Full featured project management includes collaboration across functions, social CRM applications, big data analytics & insights, access permissions, advanced reporting & Gantt charts, employee workload management, automated workflows & templates, and a mobile app.",
        },
        aventura_quattro: {
          content:
            "Full featured project management includes collaboration across functions, social CRM applications, big data analytics & insights, access permissions, advanced reporting & Gantt charts, employee workload management, automated workflows & templates, and a mobile app.",
        },
      },
      social_media_content: {
        title: "Social media content",
        vera_fund: {
          content:
            "Updating the project's social media promotion strategy. Changing visual solutions, organic and advertising activities in order to increase engagement of the existing audience and attract new potential clients. ",
        },
        coral_ridge: {
          content:
            "We had a task to create an appropriate presentation of the project of 8 luxury townhouses in social networks in order to attract the first leads already at the pre-construction stage and to understand the audience's interest in the project",
        },
        aventura_quattro: {
          content:
            "A social media profile was created on the basis of the approved brand strategy at the stage of preparing the project for construction. The content plan for the profile was developed based on the project objectives, including the tasks of familiarizing the audience with the features of the facility and its location. ",
        },
      },
      youtube_develpment: {
        title: "Youtube develpment",
        vera_fund: {
          content:
            "To begin work on YouTube promotion, an audit of the channel was conducted, and key opportunities for growth were identified. Taking into account the peculiarities of the project's audience, cooperation with partners was implemented, targeted advertising campaigns were carried out, and effective monetization strategies were set up.",
        },
        coral_ridge: {
          content:
            "YouTube campaign for the Townhouses project was launched from the channel of the group of companies, was enhanced with additional customizations. Further base for YouTube promotion was prepared for the stage of the project construction start.",
        },
        aventura_quattro: {
          content:
            "To strengthen the project promotion complex, video content creation and advertising campaigns on YouTube were approved. Videos about the project are placed on the channels of the group of companies, which strengthens the reputation of the project and the loyal attitude of potential clients",
        },
      },
    },
  },
  more_about_services: {
    block_1: {
      h2: "Business process automation",
      p: "Configuring the CRM system to digitize results and improve business efficiency",
      h3_1: "Creating a logic scheme of operational business processes for sales (deal progression):",
      li_1_1:
        "Creating a technical specification scheme in MIRO with detailed CRM funnel logic of Bitrix24 Inviting employees to Bitrix24 via email",
      li_1_2:
        "Sending invitations to employees via Email, as provided by the client",
      li_1_3: "Creating an organizational structure in Bitrix24",
      li_1_4:
        "Distributing invited employees according to the company hierarchy",
      li_1_5:
        "Optimizing lead, deal, contact, and company cards, creating up to 60 fields",
      li_1_6:
        "Creating necessary fields for new lead, repeat lead, deal, contact, and company cards",
      li_1_7:
        "Creating fields of type: string, list, date/time, map address, link, file, money, yes/no, number",
      li_1_8: "Creating a resource reservation field",
      li_1_9: "Creating fields linked to directories and CRM elements",
      li_1_10:
        "Creating additional sections in lead, deal, contact, and company cards",
      li_1_11: "Importing the client database",
      li_1_12: "Importing existing contact and company databases into Bitrix24",
      li_1_13: "Configuring CRM directories of Bitrix24",
      li_1_14: "Creating lead, deal, and contact sources",
      li_1_15: "Configuring contact types",
      li_1_16: "Configuring company types",
      li_1_17: "Configuring deal types",

      h3_2: "Configuring stages (funnels) and statuses (phases) of leads and deals",
      li_2_1:
        "Configuring statuses for the Leads funnel according to the sales process logic scheme",
      li_2_2: "Configuring statuses for the Sales Deals funnel",
      li_2_3: 'Creating an automatic funnel in Leads (for automatic "pushing")',
      li_2_4: "Creating an automatic funnel for failed deals",
      li_2_5: "Creating an automatic funnel for cold calling",
      li_2_6: "Creating an automatic funnel for feedback",
      li_2_7: "Configuring access rights to CRM Bitrix24 ",
      li_2_8: "Configuring roles (up to 12 roles)",
      li_2_9: "Assigning roles to employees and departments",
      li_2_10: "Integrating Bitrix24 with Email",
      li_2_11: "Connecting 6 Email accounts to Bitrix24",
      li_2_12: "Distributing access rights to the accounts",
      li_2_13: "Integrating Bitrix24 with telephony",
      li_2_14:
        "Connecting up to 6 mobile phone numbers using the My Calls service",
      li_2_15: "Connecting IP telephony of Bitrix24, Mango, UIS, Megafon",
      li_2_16: "Integrating Bitrix24 with the website",
      li_2_17:
        "Connecting website forms created on Tilda and other constructors with a ready solution for integration with Bitrix24",
      li_2_18:
        "Creating up to 12 CRM forms for embedding the code on other CMS",
      li_2_19:
        "Configuring callback form, online chat, and feedback form for the Bitrix24 widget on one company site",
      li_2_20: "Integrating Bitrix24 with messengers and social networks",
      li_2_21: "Integration with VKontakte and Odnoklassniki",
      li_2_22: "Integration with WhatsApp using the Wazzup or ChatApp service",
      li_2_23: "Integration with Telegram using a bot",
      li_2_24: "Integration with Telegram using the Wazzup or ChatApp service",
      li_2_25: "Integration with Avito",
      li_2_26: "Assigning responsible persons for each communication channel",
      li_2_27: "Automating Bitrix24",
      li_2_28:
        "Automatic assignment of responsible for Lead, Deal, and Contact",
      li_2_29:
        "Automatic scheduling of the next call, meeting, task, and reminder to the manager (setting up robots in leads and deals, up to 40 robots)",
      li_2_30:
        "Monitoring client processing deadlines. The robot will send notifications to the manager and the head about overdue leads and deals.",
      li_2_31:
        "Robots will automatically send messages to contacts from necessary statuses in the sales funnel",
      li_2_32:
        "Robots will automatically send messages to leads from the automatic Leads funnel",
      li_2_33:
        "Robots will automatically send messages to contacts from the automatic funnel for failed deals",
      li_2_34:
        "Robots will automatically send messages to contacts from the automatic funnel for cold calling",
      li_2_35:
        "Robots will automatically send messages to contacts from the automatic funnel for feedback",
      li_2_36:
        "Setting up automatic document generation (Commercial offer, invoice, contract, receipt)",
      li_2_37: "Setting up automatic data change for deal, contact, company",
      li_2_38: 'Configuring the "Tasks and Projects" section',
      li_2_39: "Creating templates for recurring tasks (up to 10 templates)",

      h3_3: "Configuring the appearance",
      li_3_1: "Setting the company logo",
      li_3_2: "Setting the background for all employees",
      li_3_3: "Customizing the cards of elements for all employees",
      li_3_4: "Configuring CRM marketing",
      li_3_5: "Connecting automatic emailing from the stages of the funnel",
      li_3_6:
        "Connecting automatic messaging in messengers and social networks from the stages of the funnel",
      li_3_7:
        'Connecting "Advertising customer sources" for tracking client sources.',
      li_3_8: "Configuring document template creation",
      li_3_9: "Creating Commercial offer, contract, invoice, act",
      li_3_10: "Training on system use",
      li_3_11: "Uploading a knowledge base to the Bitrix24 portal",
      li_3_12:
        "Recording a video on working with the configured system followed by uploading it to the knowledge base on the Bitrix24 portal",
    },
    block_2: {
      h2: "Content strategy",
      p: "Developing of content plans, content guidelines, scripts and terms of reference",

      1: {
        h3: "Youtube channel strategy",

        h4_1: "Analysis and Planning",
        li_1: {
          1: "Evaluate the current state of the channel: analyze audience, content, view statistics, and engagement",
          2: "Identify the target audience and their needs",
          3: "Research competitors and industry trends",
          4: "Set SMART goals and KPIs to track progress",
        },

        h4_2: "Content Creation",
        li_2: {
          1: "Develop a content plan that includes regular production of videos aligned with audience interests and trends",
          2: "Create high-quality video content, ensuring good lighting, sound, and editing",
          3: "Use compelling titles, descriptions, and tags to improve YouTube SEO",
        },

        h4_3: "Channel Promotion",
        li_3: {
          1: "Utilize social media and other platforms to attract an audience",
          2: "Collaborate with other bloggers and brands to expand reach",
          3: "Launch advertising campaigns on YouTube and other platforms to gain new subscribers",
        },

        h4_4: "Monetization",
        li_4: {
          1: "Set up monetization through YouTube's Partner Program",
          2: "Develop and sell own products or services",
          3: "Partner with brands for sponsorships and advertising",
        },

        h4_5: "Analysis and Optimization",
        li_5: {
          1: "Regularly analyze YouTube Analytics data to assess content and strategy effectiveness",
          2: "Make adjustments to content, planning, and promotion methods based on analytical data",
          3: "Continuously learn and adapt to changes in YouTube algorithms and audience preferences",
        },
        p: "This strategy should be flexible and adaptable to changes in YouTube algorithms, content trends, and audience needs. Focus on creating valuable and high-quality content to enhance audience loyalty and engagement",
      },
      2: {
        h3: "Social Media Development",

        h4_1: "Preparatory Stages",
        li_1: {
          1: "Briefing: Fill out a brief to understand the project scope and objectives.",
          2: "Target Audience Analysis (TA): Analyze the target audience to understand their preferences, behaviors, and needs",
          3: "Competitive Analysis: Study competitors to identify their strengths, weaknesses, and strategies",
          4: "Individual Promotion Strategy Development: Develop a tailored promotion strategy for the next month or half-year, considering the insights gained from the analyses",
        },

        h4_2: "Content Work",
        li_2: {
          1: "Individual Content Plan Creation: Develop a month-ahead content plan for all agreed-upon channels, tailored to the audience and strategic goals",
          2: "Preparation of Necessary Materials:",
          li: {
            1: "Conduct shoots according to the technical assignment (TA)",
            2: "Request static and video materials",
          },
          3: "Posting According to the Content Plan: Execute the content plan with scheduled postings.",
          4: "Statistics Collection and Analysis: Gather and analyze post-performance data to assess engagement, reach, and overall effectiveness",
        },

        h4_3: "Traffic Work",
        li_3: {
          1: "Organic Promotion Strategy Building: Create a strategy for organic promotion through REELS, focusing on engaging content that leverages platform-specific features",
          2: "Launching Ad Campaigns: Utilize Facebook Ads Manager to launch targeted advertising campaigns, aiming to increase reach, engagement, and conversions",
        },
      },
    },
    block_3: {
      h2: "Design",
      p: "Conceptualizing the look and feel of your project",

      1: {
        h3: "Web design",
        li: {
          1: "Website design development",
          2: "Lending (one-page website) design development",
          3: "Email/email chains design",
          4: "Visuals for social media posts, stories, advertising banners of different formats etc",
          5: "Web business identity (brochures, web business cards, letterheads, etc)",
        },
      },

      2: {
        h3: "Brand strategy & Business Identity",
        li: {
          1: "Brandbook (logo, fonts, brand colors, guide)",
          2: "Business Identity ( letter folders, business cards, letterheads, brochures, etc). All of this materials are ready for printing",
        },
      },
    },
  },
  term: {
    h1: "Terms and condition",
    h2_1: "Acceptance:",
    p_1: "It is not necessary for any Client to have signed an acceptance of these terms and conditions for them to apply. If a Client accepts a quote/estimate, then the Client will be deemed to have satisfied themselves as to the terms applying and have accepted these terms and conditions in full. Please read these terms and conditions carefully. Any purchase or use of our services implies that you have read and accepted our terms and conditions.",
    h2_2: "Charges",
    p_2_1:
      "Charges for services provided by Polyus Studio are defined in the Project Quotation that the Client receives via email. Quotations are valid for a period of 30 days (one month). Polyus Studio reserves the right to alter or decline to provide a quotation after the expiry of the 30 days (one month).",
    p_2_2:
      "Unless agreed otherwise with the Client, all application/web design and development services require an advance payment of a minimum of fifty percent (50%) of the project quotation’s total amount before the work is supplied to the Client for review. A second charge of twenty-five percent (25%) is required after the development stage, with the remaining twenty-five percent (25%) of the project quotation total due upon completion of the work, prior to upload to the server or release of materials. Payment for services is due by credit/debit, cheque, wire transfer, or email transfer.",
    h2_3: "Client Review",
    p_3: "Polyus Studio will provide the Client with an opportunity to review the look and feel and content of the website during the design phase and once the overall website development is completed. At the completion of the project, such materials will be deemed to be accepted and approved unless the Client notifies Polyus Studio otherwise within ten (10) days of the date the materials are made available to the Client.",
    h2_4: "Turnaround Time and Content Control",
    p_4_1:
      "Polyus Studio will install and publicly post or supply the Client’s website by the date specified in the project proposal/quotation/estimate, or at a date agreed with the Client upon Polyus Studio receiving initial payment, unless a delay is specifically requested by the Client and agreed by Polyus Studio.",
    p_4_2:
      "In return, the Client agrees to delegate a single individual as a primary contact to aid Polyus Studio with progressing the commission in a satisfactory and expedient manner.",
    p_4_3:
      "During the project, Polyus Studio will require the Client to provide website content; text, images, videos/movies and audio files, phone numbers, addresses, etc.",
    p_4_4:
      "Failure to Provide Required Content <br /> Polyus Studio is a small business. To remain efficient, we must ensure that work we have programmed is carried out at the scheduled time. On occasions, we may have to reject offers for other projects and enquiries to ensure that the scheduled work is completed at the time arranged.",
    p_4_5:
      "This is why we ask that you (client) provide all the required information in advance. On any occasion where progress cannot be made with your website because we have not been given the required information in the agreed time frame, and we are delayed as a result, we reserve the right to impose a surcharge of up to 25%. If your project involves Search Engine Optimization (SEO), we need the asset/content which encompass text, multimedia elements (such as videos, audio files), articles, blog posts, product descriptions, and any other information presented to end users of your application/website, in advance so that the SEO can be planned and completed efficiently.",
    p_4_6:
      "If you agree to provide us with the required information and subsequently fail to do so within 7 days (one week) of project commencement, we reserve the right to close the project and the balance remaining becomes payable immediately. Simply put, all the above condition says is do not give us the go-ahead to start until you are ready to do so.",
    h2_5: "Payment",
    p_5_1:
      "Invoices will be provided by Polyus Studio upon completion of the Project and must be paid before the application/website is published, live or hosted on the server. Invoices are only sent via email. Invoices are due upon receipt or otherwise specified by our accounts department. Accounts that remain unpaid thirty (30) days after the date of the invoice will be assessed a service charge in the amount of the higher of one percent (1%) per month.",
    p_5_2:
      "“In the event that Polyus Studio must retain a collection agency or law firm to collect past due balances owed to Polyus Studio, you (client) agree to pay any and all collection agency fees, court costs, attorney fees or incidental costs associated with collecting.”",
    h2_6: "Additional Expenses",
    p_6: "Client agrees to reimburse Polyus Studio for any additional expenses necessary for the completion of the work. Examples would be the purchase of special fonts, stock photography, videos, domain names, hosting server cost, content writing charges, etc.",
    h2_7: "Web Browsers",
    p_7_1:
      "Polyus Studio endeavors to ensure that websites are designed and developed to be compatible with the majority of visitors’ browsers. Websites are designed to function with the most popular current browsers (e.g., Firefox, Internet Explorer, Google Chrome, Safari, etc.). The Client acknowledges that Polyus Studio cannot guarantee correct functionality with all browser software across different operating systems.",
    p_7_2:
      "Polyus Studio cannot accept responsibility for web pages that do not display acceptably in new versions of browsers released after the website has been designed and handed over to the Client. Therefore, Polyus Studio reserves the right to quote for any work involved in changing the website design or website code to ensure compatibility with updated browser software.",
    h2_8: "Default",
    p_8: "Accounts unpaid thirty (30) days after the date of the invoice will be considered in default. If the Client defaults on payment and maintains any information or files on Polyus Studio’s or third-party Web servers, Polyus Studio may, at its discretion, remove all such files from all related servers. Polyus Studio is not responsible for any loss of files/data incurred due to the removal of the service. Removal of such files does not relieve the Client of the obligation to pay any outstanding charges assessed to the Client’s account. Cheques returned for insufficient funds will incur a return charge of $25.00 USD, and the Client’s account will immediately be considered in default until full payment is received. Clients with accounts in default agree to pay Polyus Studio reasonable expenses, including legal fees and costs for collection by third-party agencies, incurred by Polyus Studio in enforcing these Terms and Conditions.",
    h2_9: "Termination",
    p_9: "Termination of services by the Client must be requested in a written notice and will be effective upon receipt of such notice. Email or telephone requests for termination of services will not be honored until and unless confirmed in writing. The Client will be invoiced for design and development work completed up to the date of the first notice of cancellation for payment in full within thirty (30) days.",
    h2_10: "Indemnity",
    p_10: "All Polyus Studio services may only be used for lawful purposes. By using our services, you agree to indemnify and hold Polyus Studio harmless from any claims resulting from your use of our service that damages you or any other party.",
    h2_11: "Copyright",
    p_11: "The Client retains the copyright to data, text, files, graphics, videos, domain names, and logos provided by the Client. By providing such material, the Client grants Polyus Studio the rights to publish and use such material. The Client must obtain permission and rights to use any information or files that are copyrighted by a third party. The Client is further responsible for granting Polyus Studio permission and rights for the use of such material. A contract for website design and development and/or placement shall be regarded as a guarantee by the Client to Polyus Studio that all such permissions and authorities have been obtained. Evidence of permissions and authorities may be requested.",
    h2_12: "Standard Media Delivery",
    p_12: "Unless otherwise specified in the project quotation, this Agreement assumes that any text, photographs, and other graphics (.gif, .jpeg, .png, or .tiff format) will be provided by the Client in electronic format (ASCII text files delivered via email, FTP, or our third-party project management web tool, Basecamp). Although every reasonable attempt shall be made by Polyus Studio to return to the Client any images or printed material provided for use in the creation of the Client’s website, such return cannot be guaranteed.",
    h2_13: "Design Credit",
    p_13: "A link to Polyus Studio will appear in either small type or by a small graphic at the bottom of the Client’s website. If a graphic is used, it will be designed to fit in with the overall site design. If a client requests that the design credit be removed, Polyus Studio will remove the credits in a very reasonable time, not more than 7 business days. The Client also agrees that the website designed and developed for the Client may be presented in Polyus Studio’s portfolio.",
    h2_14: "Access Requirements",
    p_14: "If the Client’s application/website is to be installed on a third-party server, Polyus Studio must be granted temporary read/write access to the Client’s server and database directories, which must be accessible via FTP. Depending on the specific nature of the project, other resources might also need to be configured on the server.",
    h2_15: "Post-Application/Website Launch Alterations",
    p_15: "Polyus Studio cannot accept responsibility for any alterations caused by a third party occurring to the Client’s pages once installed on the server and the website is live. Such alterations include, but are not limited to, edits, additions, modifications, or deletions.",
    h2_16: "Assets Purchased on Behalf of Clients",
    p_16: "Polyus Studio may purchase assets on behalf of the Client for their applications/websites, but payment and renewal of those assets are the responsibility of the Client. The loss, cancellation, or otherwise of the asset brought about by non or late payment is not the responsibility of Polyus Studio. The Client should keep a record of the due dates for payment to ensure that payment is received in good time.",
    h2_17: "General",
    p_17: "Polyus Studio cannot accept responsibility for any alterations caused by a third party occurring to the Client’s applications/websites once installed on the server and the application/website is live. Such alterations include, but are not limited to, edits, additions, modifications, or deletions.",
    h2_18: "Governing Law",
    p_18: "This Agreement shall be governed by United States Law.",
    h2_19: "Liability",
    p_19: "Polyus Studio hereby excludes itself, its subsidiaries, companies, employees, and/or agents from all and any liability arising from any inaccuracy, omission, delay, error, or loss or damage to clients’ artwork/photos, supplied for the website. The entire liability of Polyus Studio to the Client in respect of any claim whatsoever or breach of this Agreement, whether or not arising out of negligence, shall be limited to the charges paid for the Services under this Agreement in respect of which the breach has arisen.",
    h2_20: "Severability",
    p_20_1:
      "In the event any one or more of the provisions of this Agreement shall be held to be invalid, illegal, or unenforceable, the remaining provisions of this Agreement shall be unimpaired, and the Agreement shall not be void for this reason alone. Such invalid, illegal, or unenforceable provision shall be replaced by a mutually acceptable valid, legal, and enforceable provision, which comes closest to the intention of the parties underlying the invalid.",
    p_20_2:
      "These Terms and Conditions supersede all previous representations, understandings, or agreements. The Client’s signature or payment of an advance fee constitutes agreement to and acceptance of these Terms and Conditions. Payment online is an acceptance of our terms and conditions.",
  },
  privacy: {
    h1: "Privacy policy",
    block_1: {
      h2: "We may collect and process the following information about you:",
      li_1: "information you provide by filling in forms on our website (www.polyusstudio.com); and information given in hard copy form or by any other means providing: your name; date of birth; address; interests; marketing preferences; when appropriate your credit information; and any other personal information relevant to the services we provide. This information may be provided when you are registering interest, transacting with us, subscribing to our services or for newsletters or requesting information;",
      li_2: "correspondence or other contacts you may have with us;",
      li_3: "details of transactions you carry out through our website, and any communications by telephone, in person or by any other means relating to those transactions. This may include recording certain telephone conversations, for example relating to live bidding;",
      li_4: "details of your visits to our website including, but not limited to, traffic data, web logs or other communication data and the resources you access.",
      p: "The information you provide to us will be used for processing transactions but may also be used for internal data processing, such as compiling statistical information about your interests and the use of www.polyusstudio.com. As a service to you, we may also use this information to customize the content we offer on www.polyusstudio.com.",
    },
    block_2: {
      h2: "Specific information we may collect via www.polyusstudio.com",
      p: "We may collect and process the following information obtained from your use of www.polyusstudio.com.",
    },
    block_3: {
      h2: "Cookies",
      p_1: "A cookie is a simple text file that is stored on your computer or mobile device by a website’s server and only that server will be able to retrieve or read the contents of that cookie. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier and the site name and some digits and numbers. It allows a website to remember things like your preferences or what’s in your shopping basket. Using our website will result in us placing cookies on your computer in the 3 categories set out below.",
      p_2: "Strictly necessary: These cookies are essential to enable you to move around the website and use its features, such as accessing secure areas of the website; many of the functions on our website will not work properly, or at all, if you do not allow these cookies to be used. We may therefore be unable to provide many of the services you may wish to request unless you accept the use of these cookies.",
      p_3: "Performance: These cookies collect information about how visitors use a website, for instance which pages visitors go to most often. These cookies do not collect information that identifies a visitor: all information these cookies collect is aggregated and is therefore anonymous. The information is only used to improve how the website works.",
      p_4: "Functionality: These cookies allow the website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features (for example, remembering changes you have made to text size and fonts).",
      p_5: "If you reside outside the EU, we also use a fourth type of cookie for Targeting or Advertising:",
      p_6: "Targeting or Advertising: We sometimes partner with third parties to deliver more relevant advertisements and to compile web statistics. These third parties may use cookies, web beacons and other tracking technology to monitor your visits to both Vera Realty and other websites. They monitor your online behavior and identify website usage, helping advertisers show you advertising and content that is more relevant to you. Some examples of what Targeting and Advertising Cookies do:",
      p_7: "Provide advertisers with information about your visit to our websites so they can show you advertising that is relevant to you.",
      p_8: "Limit the number of times you see an advertisement.",
      p_9: "Help measure the effectiveness of an advertising campaign.",
      p_10: "Don’t worry – none of these cookies store personally identifiable information and we only use trusted advertising partners. Just so you know, these third parties will have their own privacy policies and they may be different from ours.",
      p_11: "IP Address <br /> We may collect information about your computer, including your internet address, operating system and browser type when you visit us. Your internet address is used to help identify and gather statistical or demographic information about our customers generally. We also use this information for internal system administration to help diagnose problems with our servers, to administer our website and the customer or user experience.",
    },
    block_4: {
      h2: "How is this personal information used?",
      p_1: "Polyus Studio will collect, record, store and use your personal data world-wide among the Vera Group companies for clearly specified purposes including but not limited to:",
      li_1: "providing you with information, products or services that you request from us or which we believe would interest you in a way that is targeted and relevant. This could include but is not limited to auctions, private sales, storage services and transportation, valuations, real estate, education and insurance;",
      li_2: "carrying out our obligations arising from any contracts entered into between you and us;",
      li_3: "notifying you about changes to our products and services;",
      li_4: "ensuring that our website is presented in the most effective manner for you and your computer (unless you object) advising you of forthcoming sales, events and services.",
      li_5: "(unless you object) advising you of forthcoming sales, events and services.",
      p_2: "We may contact you for the above purposes by telephone call, email, text or SMS messaging and other means.",
    },
    block_5: {
      h2: "Information sharing",
      p_1: "We will not share your personal information with anyone outside of Polyus Studio except as required by law and as set out in this Policy. Each of the countries in which we operate has its own privacy laws. Our Privacy Policy will reflect them.",
      p_2: "However, from time to time we may need to disclose your personal information to certain third parties.",
      p_3: "Agents or contractors of Polyus Studio <br /> We may need to disclose certain personal information to our agents or contractors in order for Polyus Studio to process transactions or communicate with you. If we do so, it is on the basis that these agents/contractors are required to keep the information confidential and secure and will not use it for any purpose other than to perform the services they perform for Polyus Studio.",
      p_4: "Anti-Money Laundering and Regulatory Authorities <br /> We may need to hold and disclose certain information about you to regulatory authorities and relevant agencies to carry out anti-money laundering and trade sanctions checks and to help prevent fraud, particularly in accordance with our anti-money laundering and trade sanctions obligations. We will disclose this information as required by law.",
      p_5: "Credit Agencies <br /> We may need to disclose certain personal information about you to credit agencies to conduct appropriate checks when you are transacting with us.",
      p_6: "Legal Disclosures <br /> While we will not voluntarily disclose your personal data we may disclose such information if we are required to do so by a valid Court Order, if we are lawfully requested by government or law enforcement authorities, if we are required to do so pursuant to other legal processes, or if it becomes necessary to protect the rights or property of Polyus Studio.",
      p_7: "Worldwide Transfers and Processing <br /> As a global organization, Polyus Studio may process your personal information as described above in countries outside of the EEA. Each of these countries has differing privacy laws offering different levels of protection for your personal information. Our Privacy Policy reflects these varying practices and we will act in accordance with this Policy in all jurisdictions we operate in. By providing your personal information to Polyus Studio, you consent to us processing your personal information in any jurisdiction, in accordance with this Policy.",
      p_8: "Contacting You <br /> In order to provide you with marketing information about our products and services, we may contact you by email, text, SMS or any other messaging service, telephone, fax and by post. At the bottom of any marketing emails you will have a clearly marked unsubscribe option from future mailings. In addition, you may at any time send an email to info@polyusstudio.com asking for your name to be removed from our lists.",
      p_9: "Your Rights <br /> You have the legal right to a copy of the information which we hold about you, including information on how this will be used and to ensure this information is accurate. You also have the right to ask us not to process your personal data for marketing purposes – you can exercise this right by checking certain boxes on the forms we use to collect data or at any time by contacting us at info@polyusstudio.com.",
    },
    block_6: {
      h2: "Security",
      p_1: "We will take all reasonable and appropriate steps to protect the security and integrity of all personal information provided to our website, or by any other means electronic or otherwise.",
      p_2: "We will ensure that your personal data within Polyus Studio is suitably protected against both unauthorized physical and electronic access. These measures include an electronic firewall and various other protection measures that involve virus scanning, installation of security patches, vulnerability testing, backup and recovery planning, employee training, security audits and other steps designed constantly to improve the data protection posture. However, we cannot guarantee that information during transmission through the internet or any computer network is entirely safe from unauthorized intrusion, access or manipulation. We will have no liability for disclosure of information due to errors or unauthorized acts of third parties. In any event, where we believe personal data has been compromised, we will notify you of this.",
      p_3: "Third-Party Websites <br /> The Polyus Studio sites contain links to other websites which are not operated by Polyus Studio. The information you provide to us will not be transmitted to other websites. However, these websites may collect personal information about you in accordance with their own privacy policies. Polyus Studio is not responsible for the privacy practices or the content of such websites.",
      p_4: "How long will Polyus Studio keep my personal information? <br /> We will hold your information on our systems for as long as is necessary for the relevant activity, or for as long as is specified in any agreement between you and us.",
      p_5: "Changes to this Policy <br /> We reserve the right to amend or update this Privacy Policy and any of our privacy practices at any time. We will notify users of this website by posting any updated policy on this page and such changes will be effective immediately and without further notice. Where appropriate, we may notify you directly of changes to the Privacy Policy either through email or a prominent notice on our website.",
      p_6: "Contact <br /> If you have any queries in relation to the use and protection of your personal data please contact us at info@polyusstudio.com.",
    },
  },
};
